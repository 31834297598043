import React from 'react';
import {App, ConfigProvider, Spin, theme} from 'antd';
import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import NotFound from "./NotFound";
import Sorteggio from "./Pages/Sorteggio";
import Sorteggi from "./Pages/Sorteggi";
import {useAuth0} from "@auth0/auth0-react";



const router = createBrowserRouter([
    {
        path: "*",
        element: <NotFound/>,

    },
    {
        path: "/",
        element: <Sorteggio/>,

    },
    {
        path: "/sorteggio",
        element: <Sorteggio/>,

    },
    {
        path: "/sorteggio/:id",
        element: <Sorteggio/>,

    },
    {
        path: "/sorteggi",
        element: <Sorteggi/>,

    }
]);

function AppSorteggi() {
    // Inizio verifica se sono loggato o meno
    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
    if (isLoading) {
        /*return <div>Loading...</div>;*/
        return <Spin style={{ display: 'block', textAlign: 'center', marginTop: '10px' }} />;
    }

    if (!isAuthenticated) {
        loginWithRedirect();
        return null; // Oppure puoi mostrare un messaggio di caricamento mentre avviene il redirect
    }
    // Inizio verifica se sono loggato o meno
    //https://chatgpt.com/share/e/6733d1c0-832c-800e-8c65-0b2cfc88ba51

    return (
            <div className="App">
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#1651ea',
                            borderRadius: 2,
                            colorBgContainer: '#ffffff',
                        },
                    }}
                >
                    <App>
                        <RouterProvider router={router}/>
                    </App>
                </ConfigProvider>
            </div>
    );
}

export default AppSorteggi;