import React from "react";
import {Link} from "react-router-dom";

const NotFound: React.FC = () => {

    return (
        <div style={{"padding": "20px"}}>Pagina non trovata, vai alla <Link to="/">Home</Link></div>
    )
}


export default NotFound
