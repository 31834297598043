import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";
import AppSorteggi from "./AppSorteggi";

//secondo me l'elemente BrowserRouter va bene uguale
//https://stackoverflow.com/questions/67050966/how-to-build-a-404-page-with-react-router-dom-v6


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
    <Auth0Provider
        domain="dev-marchio94-1.eu.auth0.com"
        clientId="xySj8FXSSszOnB7YRc83hG6aULzdfMCk"

        authorizationParams={{
            redirect_uri: window.location.origin,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: process.env.REACT_APP_AUTH0_SCOPE
        }}
    >
        <AppSorteggi></AppSorteggi>
    </Auth0Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
