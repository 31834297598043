import axios from 'axios';
import useAccessToken from './authHelpers';

const useAxiosInstance = () => {
    const { getAccessToken } = useAccessToken();

    // Crea una nuova istanza di Axios
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_ENDPOINT,
    });

    // Configura l'interceptor all'interno dell'istanza stessa, senza useEffect
    axiosInstance.interceptors.request.use(async (config) => {
        try {
            const token = await getAccessToken();
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        } catch (error) {
            console.error('Errore nel recuperare il token:', error);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    return axiosInstance;
};

export default useAxiosInstance;
