import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

const useAccessToken = () => {
    const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

    const getAccessToken = useCallback(async () => {
        let token;
        try {
            token = await getAccessTokenSilently();
        } catch (e) {
            if (e?.error === 'login_required') {
                token = await getAccessTokenWithPopup();
            } else {
                console.error(e);
            }
        }
        return token;
    }, [getAccessTokenSilently, getAccessTokenWithPopup]);

    return { getAccessToken };
};

export default useAccessToken;

