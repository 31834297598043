import React, { useState, useEffect } from 'react';
import { Spin, Table, Alert, Layout, theme } from 'antd';
import { useNavigate } from 'react-router-dom';
import MenuLayout from '../Layouts/MenuLayout';
import useAxiosInstance from '../Hooks/axiosInstance';

interface Item {
    uuid: number;
    titolo: string;
}

const Sorteggi: React.FC = () => {
    const [items, setItems] = useState<Item[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
    const navigate = useNavigate();
    const axiosInstance = useAxiosInstance();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    useEffect(() => {
        setLoading(true);
        //axios.get<Item[]>(`${apiEndpoint}/games`)
            axiosInstance.get('/games').then((response) => {
                setItems(response.data);
                setError(null);
            })
            .catch((error) => {
                setError('Errore nel caricamento dei dati');
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [apiEndpoint]);

    const columns = [
        {
            title: 'Titolo',
            dataIndex: 'titolo',
            key: 'titolo',
            render: (text: string) => <span style={{ fontWeight: 'bold', color: '#1890ff' }}>{text}</span>,
        },
    ];

    const handleRowClick = (record: Item) => {
        navigate(`/sorteggio/${record.uuid}`);
    };

    return (
        <MenuLayout pageTitle="I tuoi sorteggi">
            <div style={{ padding: '16px', background: colorBgContainer }}>
                {error && <Alert message={error} type="error" showIcon style={{ marginBottom: '16px' }} />}
                <Spin spinning={loading} tip="Caricamento...">
                    <Table
                        className="sorteggi"
                        size="middle"
                        dataSource={items}
                        columns={columns}
                        rowKey="uuid"
                        pagination={false}
                        bordered
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record),
                        })}
                        rowClassName={() => 'clickable-row'}
                        style={{ background: '#fff', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}
                    />
                </Spin>
            </div>
        </MenuLayout>
    );
};

export default Sorteggi;