import React, { useEffect, useState } from 'react';
import { Layout, Menu, theme, Button, Drawer } from 'antd';
import { MenuOutlined, UsergroupAddOutlined, UploadOutlined, LinkOutlined } from '@ant-design/icons';
import './MenuLayouts.css';
import { Link, useLocation } from 'react-router-dom';
import Profile from '../Auth0/Profile';

const { Header, Content, Footer } = Layout;

interface MyLayoutProps {
    pageTitle: string;
    children: React.ReactNode;
}

const MenuLayout: React.FC<MyLayoutProps> = ({ pageTitle, children }) => {
    const [selectedKey, setSelectedKey] = useState<string>('1');
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const location = useLocation();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    useEffect(() => {
        // Update selected key based on current path
        switch (location.pathname) {
            case '/sorteggio':
                setSelectedKey('1');
                break;
            case '/sorteggi':
                setSelectedKey('2');
                break;
            default:
                setSelectedKey('1');
        }
    }, [location.pathname]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const menuItems = [
        {
            key: '1',
            icon: <UploadOutlined />,
            label: (
                <Link to="/sorteggio">Nuovo sorteggio</Link>
            ),
        },
        {
            key: '2',
            icon: <UsergroupAddOutlined />,
            label: (
                <Link to="/sorteggi">Sorteggi</Link>
            ),
        }
        /*,{
            key: '3',
            icon: <LinkOutlined />,
            label: 'Link Esterni',
            children: [
                {
                    key: '3-1',
                    label: (
                        <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">Google</a>
                    ),
                },
                {
                    key: '3-2',
                    label: (
                        <a href="https://www.amazon.com" target="_blank" rel="noopener noreferrer">Amazon</a>
                    ),
                },
            ],
        },*/
    ];

    return (
        <Layout style={{ minHeight: '100vh', background: '#fff' }}>
            <Header className="menu-header" style={{ padding: '0 16px', background: colorBgContainer, display: 'flex', alignItems: 'center' }}>
                {isMobile ? (
                    <Button
                        icon={<MenuOutlined />}
                        onClick={() => setDrawerVisible(!drawerVisible)}
                        className="menu-toggle-button"
                        style={{ marginRight: '16px', display: 'inline-block' }}
                    />
                ) : (
                    <Menu
                        theme="light"
                        mode="horizontal"
                        selectedKeys={[selectedKey]}
                        items={menuItems}
                        className="menu-horizontal"
                        style={{ marginRight: '16px' }}
                    />
                )}
                <div style={{ flex: 1, fontWeight: 'bold', fontSize: '1.2em', color: '#333', textAlign: isMobile ? 'left' : 'right' }}>{pageTitle}</div>
            </Header>
            <Drawer
                title="Menu"
                placement="left"
                onClose={() => setDrawerVisible(false)}
                visible={drawerVisible}
                bodyStyle={{ padding: 0 }}
            >
                <Menu
                    theme="light"
                    selectedKeys={[selectedKey]}
                    mode="inline"
                    items={menuItems}
                />
            </Drawer>
            <Content style={{ padding: '16px', paddingTop: '0', background: '#fff' }}>
                {children}
            </Content>
            <Footer className="footer-layout" style={{ textAlign: 'center', padding: '16px', background: '#fff', borderTop: '1px solid #e8e8e8' }}>
                <div className="user-box">
                    <Profile />
                </div>
            </Footer>
        </Layout>
    );
};

export default MenuLayout;
