import React, { useState, useEffect } from 'react';
import {
    Select, Button, Row, Col, Card, Input, Dropdown, App, Spin, Divider, Table, List, Modal, Menu, Collapse
} from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import MenuLayout from "../Layouts/MenuLayout";
import useAxiosInstance from "../Hooks/axiosInstance";
import Paragraph from "antd/lib/typography/Paragraph";

const { Option } = Select;

interface Exclusion {
    player: string;
    cant_receive_from: string[];
}

interface Data {
    titolo: string;
    players: string[];
    exclusions: Exclusion[];
    couples: Couple[];
}

interface Couple {
    receiver: string;
    gifter: string;
}

const Sorteggio: React.FC = () => {
    const [data, setData] = useState<Data>({ titolo: "", players: [], exclusions: [], couples: [] });
    const [newPlayer, setNewPlayer] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [editingPlayer, setEditingPlayer] = useState<string | null>(null);
    const { message, modal } = App.useApp();
    const { id } = useParams<{ id: string }>();
    const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
    const navigate = useNavigate();
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        if (!id) return;
        const fetchGameData = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get<Data>(`${apiEndpoint}/games/${id}`);
                setData(response.data);
            } catch (error) {
                modal.error({ title: "Attenzione", content: "Errore nel recupero dei dati dall'API: " + error });
            } finally {
                setLoading(false);
            }
        };
        fetchGameData();
    }, [id, apiEndpoint, modal]);


    const handleSave = async () => {
        console.log(axiosInstance)
        if (!data.titolo.trim()) {
            modal.error({ title: "Attenzione", content: "Il titolo è obbligatorio" });
            return;
        }
        const endpoint = id ? `${apiEndpoint}/games/${id}` : `${apiEndpoint}/games`;
        try {
            const response = await axiosInstance.post(endpoint, { data });
            setData(response.data.data);
            if (!id) navigate(`/sorteggio/${response.data.data.uuid}`);
            message.success("Dati salvati correttamente!");
        } catch (error) {
            modal.error({ title: "Attenzione", content: "Errore durante il salvataggio dei dati." });
        }
    };

    const handleDelete = async () => {
        Modal.confirm({
            title: 'Conferma eliminazione',
            content: 'Sei sicuro di voler eliminare il sorteggio?',
            onOk: async () => {
                try {
                    await axiosInstance.delete(`${apiEndpoint}/games/${id}`);
                    message.success("Sorteggio eliminato correttamente!");
                    navigate('/sorteggi');
                } catch (error) {
                    modal.error({ title: "Attenzione", content: "Errore durante l'eliminazione del sorteggio." });
                }
            },
            onCancel() {
                message.info('Eliminazione annullata');
            },
        });
    };

    const playGame = async () => {
        Modal.confirm({
            title: 'Conferma sorteggio',
            content: 'Sei sicuro di voler avviare/riavviare il sorteggio?',
            onOk: async () => {
                try {
                    const response = await axiosInstance.get(`${apiEndpoint}/games/${id}/play`);
                    if (response.data.status) {
                        setData({ ...data, couples: response.data.couples });
                    } else {
                        Modal.error({ title: "Attenzione", content: response.data.msg });
                    }
                } catch (error) {
                    Modal.error({ title: "Attenzione", content: "Errore durante il sorteggio :(" });
                }
            },
            onCancel() {
                message.info('Sorteggio annullato');
            },
        });
    };

    const sendEmails = async () => {
        Modal.confirm({
            title: 'Conferma invio email',
            content: 'Sei sicuro di voler inviare le email ai giocatori?',
            onOk: async () => {
                try {
                    const response = await axiosInstance.post(`${apiEndpoint}/games/${id}/emails`);
                    if (response.data.status) {
                        message.success("Email inviate ai giocatori!");
                    } else {
                        Modal.error({ title: "Attenzione", content: response.data.msg });
                    }
                } catch (error) {
                    Modal.error({ title: "Attenzione", content: "Errore durante l'invio delle emails :(" });
                }
            },
            onCancel() {
                message.info('Invio email annullato');
            },
        });
    };

    const handleAddPlayer = () => {
        const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
        if (!newPlayer.trim() || !emailRegex.test(newPlayer)) {
            message.error("Inserisci un'email valida");
            return;
        }
        if (data.players.includes(newPlayer)) {
            message.error("Giocatore già inserito");
        } else {
            setData({
                ...data,
                players: [...data.players, newPlayer],
                exclusions: [...data.exclusions, { player: newPlayer, cant_receive_from: [] }]
            });
            setNewPlayer('');
        }
    };

    const handleExcludeChange = (player: string, excluded: string[]) => {
        setData(prevData => ({
            ...prevData,
            exclusions: prevData.exclusions.map(exclusion =>
                exclusion.player === player ? { ...exclusion, cant_receive_from: excluded } : exclusion
            )
        }));
    };

    const handlePlayerAction = (action: string, player: string) => {
        if (action === "Elimina") {
            setData(prevData => ({
                ...prevData,
                players: prevData.players.filter(p => p !== player),
                exclusions: prevData.exclusions.filter(exclusion => exclusion.player !== player),
                couples: prevData.couples.filter(couple => couple.gifter !== player && couple.receiver !== player)
            }));
        } else if (action === "Rinomina") {
            setEditingPlayer(player);
        }
    };

    const handleRenamePlayer = (oldName: string, newName: string) => {
        if (!newName.trim()) {
            message.error("Il nome non può essere vuoto");
            return;
        }
        setData(prevData => ({
            ...prevData,
            players: prevData.players.map(p => p === oldName ? newName : p),
            exclusions: prevData.exclusions.map(exclusion =>
                exclusion.player === oldName
                    ? { ...exclusion, player: newName }
                    : {
                        ...exclusion,
                        cant_receive_from: exclusion.cant_receive_from.map(p => p === oldName ? newName : p)
                    }
            ),
            couples: prevData.couples.map(couple =>
                ({
                    ...couple,
                    gifter: couple.gifter === oldName ? newName : couple.gifter,
                    receiver: couple.receiver === oldName ? newName : couple.receiver
                })
            )
        }));
        setEditingPlayer(null);
    };

    return (
        <MenuLayout pageTitle="Giocatori e regole">
            <div style={{padding: '20px', backgroundColor: '#f0f2f5', borderRadius: '8px'}}>
                <Paragraph ellipsis={{rows: 2, expandable: true, symbol: 'Leggi di più'}}>
                    Questo sito permette di sorteggiare le coppie per la cena del "<i>Babbo Natale segreto</i>", evitando quelle incompatibili.
                    <br/> Inserisci i nomi dei giocatori, assicurandoti che ciascuno contenga un'email valida. Quando hai finito, clicca su <strong>Salva giocatori</strong> e poi su
                    <strong> Sorteggia coppie</strong>. <br></br>Infine, puoi inviare un'email ad ogni giocatore per comunicargli a chi dovrà fare il regalo! <br/>
                    Puoi anche inserire i giocatori e riprendere il sorteggio in un secondo momento, basta salvarlo e ricercarlo nella sezione "Sorteggi" dell'app.
                </Paragraph>
                <Spin spinning={loading}>
                    <div style={{marginBottom: '20px'}}>
                        <Input
                            placeholder="Titolo del sorteggio"
                            value={data.titolo}
                            onChange={(e) => setData({...data, titolo: e.target.value})}
                            style={{
                                maxWidth: '400px',
                                borderRadius: '8px',
                                border: '1px solid #d9d9d9',
                                padding: '10px'
                            }}
                        />
                    </div>
                    <h2 style={{marginBottom: '20px', color: '#1890ff'}}>Giocatori</h2>
                    <Row gutter={[16, 16]}>
                        {data.exclusions.map(({player, cant_receive_from}) => (
                            <Col key={player} xs={24} sm={12} md={8} lg={6} style={{marginBottom: '16px'}}>
                                <Card
                                    title={
                                        editingPlayer === player ? (
                                            <Input
                                                defaultValue={player}
                                                onPressEnter={(e) => handleRenamePlayer(player, e.currentTarget.value)}
                                                onBlur={(e) => handleRenamePlayer(player, e.currentTarget.value)}
                                                autoFocus
                                            />
                                        ) : (
                                            player
                                        )
                                    }
                                    extra={
                                        <Dropdown overlay={
                                            <Menu>
                                                <Menu.Item key="elimina"
                                                           onClick={() => handlePlayerAction("Elimina", player)}>
                                                    Elimina
                                                </Menu.Item>
                                                <Menu.Item key="rinomina"
                                                           onClick={() => handlePlayerAction("Rinomina", player)}>
                                                    Rinomina
                                                </Menu.Item>
                                            </Menu>
                                        } trigger={["click"]}>
                                            <Button type="text" icon={<EllipsisOutlined/>}/>
                                        </Dropdown>
                                    }
                                    style={{boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '8px'}}
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="da chi non può ricevere?"
                                        value={cant_receive_from}
                                        onChange={(excluded) => handleExcludeChange(player, excluded)}
                                        style={{width: '100%'}}
                                    >
                                        {data.players.filter(p => p !== player).map(p => <Option key={p}>{p}</Option>)}
                                    </Select>
                                </Card>
                            </Col>
                        ))}
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Card title="Nuovo Giocatore"
                                  style={{boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '8px'}}>
                                <Input
                                    placeholder="Nome del nuovo giocatore"
                                    value={newPlayer}
                                    onChange={e => setNewPlayer(e.target.value)}
                                    style={{marginBottom: '10px'}}
                                />
                                <Button type="primary" onClick={handleAddPlayer} block>Aggiungi</Button>
                            </Card>
                        </Col>
                    </Row>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        marginTop: '20px',
                        position: 'relative'
                    }}>
                        <Button type="primary" onClick={handleSave}>Salva giocatori</Button>
                    </div>
                    <Divider style={{margin: '40px 0'}}/>
                    {data.couples.length > 0 && (
                        <>
                            <h2 style={{marginBottom: '20px', color: '#1890ff'}}>Coppie</h2>
                            <Collapse bordered={false} style={{marginBottom: '20px'}}>
                                <Collapse.Panel
                                    header={`${data.couples.length} coppie sorteggiate (espandi per vederle)`}
                                    key="1"
                                    /*style={{ boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', background: 'linear-gradient(to right, #ffffff, #f0f8ff)' }}*/
                                    style={{boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', background: '#ffffff'}}
                                >
                                    <Table
                                        dataSource={data.couples}
                                        columns={[
                                            {title: 'chi', dataIndex: 'gifter', key: 'gifter'},
                                            {title: 'fa il regalo a:', dataIndex: 'receiver', key: 'receiver'}
                                        ]}
                                        pagination={false}
                                        rowKey={(record) => `${record.gifter}-${record.receiver}`}
                                        size="small"
                                        bordered
                                    />
                                </Collapse.Panel>
                            </Collapse>
                        </>
                    )}


                    {id && (
                        <Button type="primary" onClick={playGame} style={{marginTop: '20px', marginRight: '10px'}}>
                            {data.couples.length ? 'Risorteggia coppie' : 'Sorteggia coppie'}
                        </Button>
                    )}
                    {id && data.couples.length > 0 && (
                        <Button type="default" onClick={sendEmails} style={{marginTop: '20px'}}>
                            Invia email ai giocatori
                        </Button>
                    )}
                </Spin>
            </div>
            {id && (
                <a onClick={handleDelete} style={{ color: 'red', fontSize: 'small', cursor: 'pointer', float: 'right', marginTop: '20px', marginRight : '15px' }}>elimina sorteggio</a>
            )}
        </MenuLayout>
    );
};

export default Sorteggio;
