import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutButton from './LogoutButton';
import LoginButton from './LoginButton';
import { Spin, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const Profile: React.FC = () => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

    const getAccessToken = async () => {
        let token;
        try {
            token = await getAccessTokenSilently();
        } catch (e) {
            if ((e as any).error === 'login_required') {
                token = await getAccessTokenWithPopup();
            } else {
                console.error(e);
            }
        }
        return token;
    };

    if (isLoading) {
        return <Spin tip="Caricamento..." style={{ display: 'block', textAlign: 'center', marginTop: '10px' }} />;
    }

    if (!isAuthenticated) {
        return <LoginButton />;
    }



    return (
        <div style={{ display: 'flex', alignItems: 'center', padding: '8px', background: '#f0f2f5', borderRadius: '8px' }}>
            <Avatar size={40} src={user?.picture} icon={!user?.picture && <UserOutlined />} style={{ marginRight: '10px' }} />
            <div>
                <div style={{ fontWeight: 'bold', fontSize: '1em' }}>{user?.name}</div>
                <div style={{ color: 'gray', fontSize: '0.9em' }}>{user?.email}</div>
            </div>
            <div style={{ marginLeft: 'auto' }}><LogoutButton /></div>
        </div>
    );
};

export default Profile;
